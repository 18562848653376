<template>
  <div class="auth__form__content" id="auth-login">
    <form v-on:submit="resetPassword">
      <div v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="(error, key) in errors" :key="key">
            {{ error }}
          </li>
        </ul>
      </div>

      <h2 class="has-text-centered">RESET PASSWORD</h2>
      <b-field label="New Password">
        <b-input
          v-model="password"
          type="password"
          placeholder="password"
          required
        ></b-input>
      </b-field>
      <b-field label="Confirm Password">
        <b-input
          type="password"
          placeholder="password confirmation"
          v-model="passwordConfirmation"
          required
        ></b-input>
      </b-field>
      <b-field class="is-flex is-justify-content-center">
        <b-button native-type="submit">Submit</b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: this.$route.params.token,
      userId: null,
      password: null,
      passwordConfirmation: null,
      errors: [],
    }
  },

  methods: {
    resetPassword: function (e) {
      e.preventDefault()
      this.errors = []
      console.log(this.password)
      console.log(this.passwordConfirmation)
      if (this.password != this.passwordConfirmation) {
        this.errors.push('Password Not Equal')
      } else {
        let token = this.token
        let userId = this.userId
        let password = this.password
        let redirect = '/auth/login'
        this.$store
          .dispatch('auth/resetPassword', { token, userId, password, redirect })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: `Success Change Password!`,
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    checkResetPasswordToken() {
      let token = this.token
      this.$store
        .dispatch('auth/checkResetPasswordToken', { token })
        .then((response) => {
          this.userId = response.userId
          this.token = response.token
        })
        .catch((error) => {
          this.$router.push('/auth/login')
          console.log(error)
        })
    },
  },
  created() {
    if (this.token != null) {
      this.checkResetPasswordToken()
    }
  },
}
</script>
